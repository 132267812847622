

const getBaseUrl = () => {
    if(window?.location?.origin){
        var base_url = window.location.origin;

        var apiUrl = `${base_url}/limeeasy/limeeasy-api/api`;
        return apiUrl;
    }
    else{
        return "";
    }
}

const getTemplate = () => {
    if(window?.location?.origin){
        var base_url = window.location.origin;

        var apiUrl = `${base_url}/limeeasy/limeeasy-api/template_limeeasy.xlsx`;
        return apiUrl;
    }
    else{
        return "";
    }
}


const appConfig = {
    pathUserTemplate: 'https://demo-api.eventqiu.com/template_demo.xlsx',
    // pathUserTemplate: getTemplate(),
    pathCodeTemplate: '',
    apiPrefix: 'https://scs2025-api.eventqiu.com/api',
    apiMedia: 'https://scs2025-api.eventqiu.com/media',
    // apiPrefix: getBaseUrl(),
    authenticatedEntryPath: '/admin-eventqiu/user',
    unAuthenticatedEntryPath: '/admin-eventqiu/sign-in',
    adminPath: '/admin-eventqiu',
    tourPath: '/',
    notFound: '/not-found',
    locale: 'en',
    enableMock: false,
}

export default appConfig
